const accountMgmt = [
  {
    path: '/user-management',
    name: 'User Management',
    component: () => import('@/views/accountMgmt/userManagement/index.vue'),
    meta: {
      // resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/role-management',
    name: 'Role Management',
    component: () => import('@/views/accountMgmt/roleManagement/index.vue'),
    meta: {
      // resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/activity-record',
    name: 'Activity Record',
    component: () => import('@/views/accountMgmt/activityRecord/index.vue'),
    meta: {
      // resource: 'Auth',
      action: 'read',
    },
  },
]

export default accountMgmt
