<template>
  <b-col
    :md="item.md ? item.md : 4"
    :class="`${item.iClass}`"
  >
    <div v-if="item.itype==='input'">
      <label for="inline-form-custom-select-pref">{{ item.label }}:</label>
      <b-input-group>
        <b-form-input
          v-bind="$attrs"
          autocomplete="off"
          :value="modelValue"
          trim
          @input="(val) => $emit('update:modelValue', val.trim())"
        />
      </b-input-group>
    </div>

    <div v-if="item.itype==='datetime'">
      <label for="inline-form-custom-select-pref">{{ item.label }}:</label>
      <date-picker
        :time-range-default="dateTime"
        @TimeRange="getTimeRange"
      />
    </div>

    <div v-if="item.itype === 'select'">
      <label for="inline-form-custom-select-pref">{{ item.label }}:</label>
      <v-select
        v-model="selectedItem"
        v-bind="$attrs"
        :options="item.Options"
        :label="item.labelKey"
        :value="item.valueKey"
        :clearable="false"
        @input="(val) => $emit('update:modelValue', val)"
      />
    </div>

    <div v-if="item.itype==='datetimeTerms'">
      <label for="inline-form-custom-select-pref">{{ item.label }}:</label>
      <b-input-group>
        <b-input-group-prepend>
          <b-dropdown
            :text="mode[item.labelKey]"
            variant="outline-primary"
          >
            <b-dropdown-item
              v-for="(option, index) in item.Options"
              :key="index"
              @click="modeOptionChange(option)"
            >
              {{ option[item.labelKey] }}
            </b-dropdown-item>
          </b-dropdown>
        </b-input-group-prepend>
        <date-picker
          style="flex: 1"
          :time-range-default="datetimeTerms"
          @TimeRange="getTimeRangeTerms"
        />
      </b-input-group>
    </div>

    <div v-if="item.itype==='modeinput'">
      <label for="inline-form-custom-select-pref">{{ item.label }}:</label>
      <b-input-group>
        <b-input-group-prepend>
          <b-dropdown
            :text="mode[item.labelKey]"
            variant="outline-primary"
          >
            <b-dropdown-item
              v-for="(option, index) in item.Options"
              :key="index"
              @click="modeOptionChange(option)"
            >
              {{ option[item.labelKey] }}
            </b-dropdown-item>
          </b-dropdown>
        </b-input-group-prepend>
        <b-form-input
          :placeholder="item.placeholder"
          v-bind="$attrs"
          autocomplete="off"
          :value="searchKey"
          trim
          @input="(val) => getSearchTerms('undefined', val)"
        />
      </b-input-group>
    </div>

    <div
      v-if="item.itype==='numberMaxMin'"
      class="min-to-max"
    >
      <label for="inline-form-custom-select-pref">{{ item.label }}:</label>
      <!-- :value="minNumber" -->
      <b-form-input
        v-model="minNumber"
        type="number"
        v-bind="$attrs"
        autocomplete="off"
        trim
        placeholder="Min"
        @input="(val) => fetchMaxMin(val, 'undefined')"
        @keyup="(event) => keydownMaxmin(event.target.value, 'undefined')"
      />
    </div>
    <div
      v-if="item.itype==='numberMaxMin'"
      class="min-to-max-line"
    >
      <label for="inline-form-custom-select-pref" />
      <div class="min-to-max-line-child">
        —
      </div>
    </div>
    <div
      v-if="item.itype==='numberMaxMin'"
      class="min-to-max"
    >
      <label for="inline-form-custom-select-pref">&nbsp;</label>
      <b-input-group>
        <!-- :value="maxNumber" -->
        <b-form-input
          v-model="maxNumber"
          type="number"
          v-bind="$attrs"
          autocomplete="off"
          trim
          placeholder="Max"
          @input="(val) => fetchMaxMin('undefined', val)"
          @keyup="(event) => keydownMaxmin('undefined', event.target.value)"
        />
      </b-input-group>
    </div>

  </b-col>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import {
  BRow, BCol, BInputGroup, BFormInput, BInputGroupPrepend, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import DatePicker from '@/component/Datepicker.vue'
import vSelect from 'vue-select'

export default defineComponent({
  setup(props, context) {
    const dateTime = ref([])
    const datetimeTerms = ref([])
    const getTimeRange = time => {
      context.emit('update:modelValue', time)
    }
    // modeinput
    const searchKey = ref('')
    const mode = ref({ ...props.item.defaultMode })
    const modeValue = ref(mode.value[props.item.labelValue])
    const getSearchTerms = (mValue, sKey) => {
      // input
      if (mValue === 'undefined') {
        searchKey.value = sKey
        modeValue.value = mode.value.value
      }
      if (sKey === 'undefined') {
        modeValue.value = mValue
      }
      context.emit('update:modelValue', { modeValue: modeValue.value, searchKey: searchKey.value })
    }
    const modeOptionChange = option => {
      mode.value = option
      getSearchTerms(option[props.item.labelValue], 'undefined')
    }
    const getTimeRangeTerms = time => {
      getSearchTerms('undefined', time)
    }
    // select
    const selectedItem = ref({ ...props.item.defaultSelected })
    // max min
    const maxNumber = ref('')
    const minNumber = ref('')
    const reset = () => {
      if (props.item.itype === 'datetime') {
        // 仅时间
        dateTime.value = []
        context.emit('update:modelValue', [])
      } else if (props.item.itype === 'datetimeTerms') {
        // 筛选 + 时间
        datetimeTerms.value = []
        mode.value = props.item.defaultMode
        context.emit('update:modelValue', { modeValue: mode.value[props.item.labelValue], searchKey: [] })
      } else if (props.item.itype === 'modeinput') {
        searchKey.value = ''
        mode.value = props.item.defaultMode
        context.emit('update:modelValue', { modeValue: mode.value[props.item.labelValue], searchKey: searchKey.value })
      } else if (props.item.itype === 'select') {
        selectedItem.value = props.item.defaultSelected
        context.emit('update:modelValue', selectedItem.value)
      } else if (props.item.itype === 'numberMaxMin') {
        maxNumber.value = ''
        minNumber.value = ''
        context.emit('update:modelValue', { min: '', max: '' })
      } else {
        context.emit('update:modelValue', '')
      }
    }
    const fetchMaxMin = (min, max) => {
      if (min === 'undefined') {
        maxNumber.value = max
      }
      if (max === 'undefined') {
        minNumber.value = min
      }
      context.emit('update:modelValue', { min: minNumber.value, max: maxNumber.value })
    }
    const keydownMaxmin = (min, max) => {
      if (min === 'undefined') {
        maxNumber.value = max
      }
      if (max === 'undefined') {
        minNumber.value = min
      }
      if (Number(minNumber.value) < 0) minNumber.value = ''
      if (Number(maxNumber.value) < 0) maxNumber.value = ''
      if (Number(minNumber.value) > 1000) minNumber.value = 1000
      if (Number(maxNumber.value) > 1000) maxNumber.value = 1000
      // 不用比较左右大小
      // if (minNumber.value.length > 0 && maxNumber.value.length > 0 && Number(minNumber.value) > Number(maxNumber.value)) {
      //   maxNumber.value = ''
      //   minNumber.value = ''
      // }
      context.emit('update:modelValue', { min: minNumber.value, max: maxNumber.value })
    }
    return {
      reset,
      getTimeRange,
      dateTime,
      getTimeRangeTerms,
      datetimeTerms,
      // *** modeinput ***
      getSearchTerms,
      searchKey,
      mode,
      modeOptionChange,
      fetchMaxMin,
      keydownMaxmin,
      // *** max min ***
      minNumber,
      maxNumber,
      // *** select ***
      selectedItem,
    }
  },
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    modelValue: {
      type: [String, Object, Array, Number],
      default: '',
    },
  },
  components: {
    BRow, BCol, BInputGroup, BFormInput, BInputGroupPrepend, BDropdown, BDropdownItem, DatePicker, vSelect,
  },
})
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.min-to-max {
  width: 45%;
  float:left;
}
.min-to-max-line {
  width: 10%;
  float:left;
}
.min-to-max-line-child {
  text-align: center;
  line-height: 38px;
}
</style>
