const settings = [
  {
    path: '/deposit-channel',
    name: 'Deposit Channel',
    component: () => import('@/views/settings/depositChannel.vue'),
    meta: {
      action: 'read',
    },
  },
  {
    path: '/withdraw-channel',
    name: 'Withdraw Channel',
    component: () => import('@/views/settings/withdrawChannel.vue'),
    meta: {
      action: 'read',
    },
  },
  // {
  //   path: '/service-fee',
  //   name: 'Service Fee',
  //   component: () => import('@/views/settings/serviceFee.vue'),
  //   meta: {
  //     // resource: 'Auth',
  //     action: 'read',
  //   },
  // },
  {
    path: '/smileone-goods',
    name: 'Product List',
    component: () => import('@/views/settings/smileoneGoods.vue'),
    meta: {
      // resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/configurations',
    name: 'Configurations',
    component: () => import('@/views/settings/Configurations.vue'),
    meta: {
      // resource: 'Auth',
      action: 'read',
    },
  },
]

export default settings
