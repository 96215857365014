<template>
  <div>
    <span
      v-if="item.tType === 'prize_distribution_datetime'"
      class="text-nowrap"
    > {{ updateDate(gvalue, timeZone) }}</span>
    <span
      v-if="item.tType === 'prize_distribution_delete'"
      class="text-nowrap"
    >
      <b-button
        v-hasBtn="`prize_distribution_generator`"
        variant="secondary"
        class="btn-icon mr-1"
        size="sm"
        @click="() =>{ item.callback('delete',gdata) }"
      ><feather-icon
        icon="Trash2Icon"
      /></b-button>
      <b-button
        v-hasBtn="`prize_distribution_generator`"
        variant="success"
        class="btn-icon mr-1"
        size="sm"
        @click="() =>{ item.callback('download', gdata) }"
      ><feather-icon
        icon="DownloadIcon"
      /></b-button>
      <b-button
        v-hasBtn="`prize_distribution_generator`"
        variant="primary"
        class="btn-icon"
        size="sm"
        @click="() =>{ item.callback('email', gdata) }"
      ><feather-icon
        icon="MailIcon"
      /></b-button>
    </span>
    <span
      v-if="item.tType === 'prize_distribution_payout'"
      class="text-nowrap"
    >
      <!-- <b-button
        v-hasBtn="`prize_distribution_list`"
        :disabled="!(gdata.kyc_status === 4 && gdata.status === 3)"
        variant="primary"
        @click="() =>{ item.callback('payout', gdata)}"
      >Pay-out</b-button> -->
    </span>
    <span
      v-if="item.tType === 'prize_distribution_user_id'"
      class="text-nowrap"
    >
      <b-link
        v-hasBtn="`link_user_list`"
        :to="{
          path: '/usercenter/user-detail',
          query: { id: gdata.user_id, timezone: timeZone },
        }"
        class="font-weight-bold"
        target="_blank"
      >
        {{ gvalue }}
      </b-link>
      <span v-hasBtn="`no_user_list`">{{ gvalue }}</span>
    </span>

  </div>

</template>
<script>
import { defineComponent, computed, getCurrentInstance } from '@vue/composition-api'
import { BButton, BLink } from 'bootstrap-vue'
import { updateDate } from '@/libs/utils'

export default defineComponent({
  name: 'generator',
  props: ['item', 'gvalue', 'gdata'],
  setup() {
    const { proxy } = getCurrentInstance()
    const { $store } = proxy
    const timeZone = computed(() => $store.state.wallet.timeZone, { immediate: true, deep: true })
    return { updateDate, timeZone }
  },
  components: {
    BButton, BLink,
  },
})
</script>
