<template>
  <b-card>
    <b-form @submit.prevent>
      <b-row>
        <form-item
          v-for="(item) in form"
          ref="formItem"
          :key="item.imodel"
          :model-value.sync="queryModel[item.imodel]"
          :item="item"
          v-bind="item"
        />
      </b-row>
      <b-row class="mt-2">
        <b-col class="text-center">
          <slot name="buttons" />
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  defineComponent, getCurrentInstance, ref, watch,
} from '@vue/composition-api'
import {
  BCard, BForm, BRow, BCol, BButton,
} from 'bootstrap-vue'
import FormItem from './FormItem.vue'

export default defineComponent({
  name: 'QueryForm',
  props: ['queryForm', 'form'],
  setup(props, context) {
    const { proxy } = getCurrentInstance()
    const queryModel = ref({
      ...props.queryForm,
    })
    watch(queryModel.value, value => {
      context.emit('update:queryForm', value)
    })
    const reset = () => {
      context.emit('update:queryForm', {})
      proxy.$refs.formItem.forEach(func => func.reset())
    }
    return {
      queryModel,
      reset,
    }
  },
  components: {
    BCard, BForm, BRow, BCol, BButton, FormItem,
  },
})
</script>
