var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.item.tType === 'merchant_merchant_id')?_c('span',{staticClass:"text-nowrap"},[_c('b-link',{staticClass:"font-weight-bold",attrs:{"to":{
        path: '/merchant-detail',
        query: { id: _vm.gdata.merchant_id, timezone: _vm.timeZone },
      },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.gvalue)+" ")])],1):(_vm.item.tType === 'merchant_evidence_of_deposit')?_c('span',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"flat-primary","size":"sm"},on:{"click":function () { _vm.item.callback('evidence_of_deposit', _vm.gdata) }}},[_vm._v(" View ")])],1):(_vm.item.tType === 'merchant_deposit_transaction_id')?_c('span',{staticClass:"text-nowrap"},[_c('b-link',{staticClass:"font-weight-bold",attrs:{"to":{
        path: '/merchant-deposit-detail',
        query: { id: _vm.gdata.transaction_id, timezone: _vm.timeZone },
      },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.gvalue)+" ")])],1):(_vm.item.tType === 'merchant_withdraw_transaction_id')?_c('span',{staticClass:"text-nowrap"},[_c('b-link',{staticClass:"font-weight-bold",attrs:{"to":{
        path: '/merchant-withdraw-detail',
        query: { id: _vm.gdata.transaction_id, timezone: _vm.timeZone },
      },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.gvalue)+" ")])],1):(_vm.item.tType === 'merchant_security')?_c('span',{staticClass:"text-nowrap"},[_c('b-button',{staticClass:"btn-icon mr-1",attrs:{"variant":"secondary","size":"sm"},on:{"click":function () { _vm.item.callback('lock',_vm.gdata) }}},[_c('feather-icon',{attrs:{"icon":"UnlockIcon"}})],1),_c('b-button',{staticClass:"btn-icon mr-1",attrs:{"variant":"success","size":"sm"},on:{"click":function () { _vm.item.callback('googleAuthenticator', _vm.gdata) }}},[_c('feather-icon',{attrs:{"icon":"RefreshCcwIcon"}})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }