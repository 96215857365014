const merchantCenter = [
  // Merchant Center
  {
    path: '/merchant-list',
    name: 'Merchant Center List',
    component: () => import('@/views/merchantCenter/merchantList/index.vue'),
    meta: {
      action: 'read',
    },
  },
  {
    path: '/merchant-detail',
    name: 'Merchant Detail',
    component: () => import('@/views/merchantCenter/merchantList/detail.vue'),
    meta: {
      action: 'read',
      layout: 'full',
    },
  },
  // deposit
  {
    path: '/merchant-deposit',
    name: 'Merchant Deposit',
    component: () => import('@/views/merchantCenter/deposit/index.vue'),
    meta: {
      action: 'read',
    },
  },
  {
    path: '/merchant-deposit-detail',
    name: 'Merchant Deposit Detail',
    component: () => import('@/views/merchantCenter/deposit/detail.vue'),
    meta: {
      action: 'read',
      layout: 'full',
    },
  },
  // withdraw
  {
    path: '/merchant-withdraw',
    name: 'Merchant Withdraw',
    component: () => import('@/views/merchantCenter/withdraw/index.vue'),
    meta: {
      action: 'read',
    },
  },
  {
    path: '/merchant-withdraw-detail',
    name: 'Merchant Withdraw Detail',
    component: () => import('@/views/merchantCenter/withdraw/detail.vue'),
    meta: {
      action: 'read',
      layout: 'full',
    },
  },
]

export default merchantCenter
