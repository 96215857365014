<template>
  <div>
    <date-picker
      ref="date-range"
      v-model="timeRange"
      prefix-class="xmx"
      type="datetime"
      placeholder="Select datetime range"
      :range="!range"
      :show-time-panel="showTimeRangePanel"
      confirm
      :shortcuts="!range?shortcuts:[]"
      value-type="format"
      style="width:100%"
      :popup-class="!range?'date-time-style':''"
      :disabled-date="notBeforeToday"
      :disabled-time="notBeforeClock"
      @close="handleRangeClose"
      @confirm="confirm"
      @clear="clear"
      @blur="blur"
    >
      <template v-slot:footer>
        <button
          class="mx-btn mx-btn-text"
          @click="toggleTimeRangePanel"
        >
          {{ showTimeRangePanel ? 'select date' : 'select time' }}
        </button>
      </template>
    </date-picker>
  </div>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { createNamespacedHelpers } from 'vuex'
import { handlerTimestamp } from '@/libs/utils'

const { mapState: mapStateWallet } = createNamespacedHelpers('wallet')
const moment = require('moment-timezone')

export default {
  components: {
    DatePicker,
  },
  props: {
    timeRangeDefault: {
      type: Array,
      default: () => [],
      required: true,
    },
    range: {
      type: Boolean,
      default: false,
    },
    disabledBeforeTime: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      timeRange: this.timeRangeDefault,
      showTimeRangePanel: false,
      shortcuts: [
        {
          text: 'today',
          onClick: () => this.emitTime(0, 0),
        },
        {
          text: 'Yesterday',
          onClick: () => this.emitTime(1, 1),
        },
        {
          text: 'Last week',
          onClick: () => this.emitTime(7, 1),
        },
        {
          text: 'Last month',
          onClick: () => this.emitTime(30, 1),
        },
        {
          text: 'Last three month',
          onClick: () => this.emitTime(90, 1),
        },
      ],
    }
  },
  computed: {
    ...mapStateWallet(['timeZone']),
  },
  watch: {
    timeZone() {
      // 时区变化时，清空时间值
      // 不清空时间值
      // this.timeRange = this.timeRangeDefault
      // const t1 = this.timeRange[0]
      // const t2 = this.timeRange[1]
      // this.$emit('TimeRange', handlerTimestamp(t1, t2, value))
    },
    timeRangeDefault(value) {
      // const zoneName = moment.tz.guess()
      if (value.length === 0) {
        this.timeRange = this.timeRangeDefault
      } else if (value.length === 1) {
        this.timeRange = moment.tz(value[0] * 1000, 'UTC').format('YYYY-MM-DD 00:00:00')
      } else {
        this.timeRange = [
          moment.tz(value[0] * 1000, 'UTC').format('YYYY-MM-DD 00:00:00'),
          moment.tz(value[1] * 1000, 'UTC').format('YYYY-MM-DD 23:59:59'),
        ]
      }
    },
  },
  mounted() {
    this.timeRange = this.timeRangeDefault
    // console.log('时区变化', this.timeZone)
    this.initDateRangeFont()
    // 监听窗口改变
    window.onresize = () => {
      this.initDateRangeFont()
    }
    this.$nextTick(() => {
      const iptEle = document
        .querySelectorAll('.xmx-input-wrapper')[0]
        .querySelectorAll('input')[0]
      iptEle.setAttribute('readonly', 'readonly')
    })
  },
  methods: {
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel
    },
    handleRangeClose() {
      this.showTimeRangePanel = false
    },
    confirm() {
      if (typeof this.timeRange === 'string') {
        const timesRange = handlerTimestamp(this.timeRange, '', this.timeZone)
        this.$emit('TimeRange', [timesRange])
      } else {
        const timesRange = handlerTimestamp(this.timeRange[0], this.timeRange[1], this.timeZone)
        this.$emit('TimeRange', timesRange)
      }
    },
    clear() {
      this.$emit('TimeRange', [])
    },
    blur() {
    //   const t1 = this.timeRange[0]
    //   const t2 = this.timeRange[1]
    //   this.$emit('TimeRange', handlerTimestamp(t1, t2, this.timeZone))
    },
    emitTime(start, end) {
      const t1 = moment().subtract(start, 'days').format('YYYY-MM-DD 00:00:00')
      const t2 = moment().subtract(end, 'days').format('YYYY-MM-DD 23:59:59')
      this.$emit('TimeRange', handlerTimestamp(t1, t2, this.timeZone))
      return [
        new Date(
          moment().subtract(start, 'days').format('YYYY-MM-DD 00:00:00'),
        ),
        new Date(moment().subtract(end, 'days').format('YYYY-MM-DD 23:59:59')),
      ]
    },
    initDateRangeFont() {
      if (!this.$refs['date-range']) {
        return false
      }
      const screenW = document.body.clientWidth
      const xmxInputEles = document.querySelectorAll('.xmx-input')
      if (screenW <= 1336) {
        if (xmxInputEles.length > 1) {
          for (let i = 0; i < xmxInputEles.length; i += 1) {
            xmxInputEles[i].style.fontSize = '12px'
          }
        } else {
          this.$refs['date-range'].$el.querySelectorAll(
            'input.xmx-input',
          )[0].style.fontSize = '12px'
        }
      } else if (xmxInputEles.length > 1) {
        for (let i = 0; i < xmxInputEles.length; i += 1) {
          xmxInputEles[i].style.fontSize = '14px'
        }
      } else {
        this.$refs['date-range'].$el.querySelectorAll(
          'input.xmx-input',
        )[0].style.fontSize = '14px'
      }
      return ''
    },
    notBeforeToday(date) {
      if (!this.disabledBeforeTime) {
        return false
      }
      return date < new Date(new Date().setHours(0, 0, 0, 0))
    },
    notBeforeClock(date) {
      if (!this.disabledBeforeTime) {
        return false
      }
      return date < new Date()
    },
  },
}
</script>
<style lang="scss">
// vue2-datepicker 统一样式
.mx-datepicker-main {
  .mx-datepicker-sidebar {
    width: 10rem !important;
  }
  .mx-datepicker-sidebar + .mx-datepicker-content {
    margin-left: 140px !important;
  }
}
.mx-datepicker-range {
  width: 100% !important;
  .mx-input {
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    width: 100%;
    height: 2.714rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;
    color: #6e6b7b;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}
.xmx-datepicker-range {
  width: 100% !important;
}
.xmx-input {
  height: 38px !important;
}
.xmx-datepicker-range .xmx-input {
  border-color: #d8d6de;
  box-shadow: none;
}
.xmx-datepicker-range .xmx-input:hover {
  border-color: #d8d6de;
}
.xmx-datepicker-footer .mx-btn-text:hover {
  color: #04b88f;
}
.date-time-style .xmx-datepicker-sidebar {
  width: 128px;
}
.date-time-style .xmx-datepicker-content {
  margin-left: 128px !important;
}
.xmx-input-wrapper .xmx-input {
  padding-left: 7px !important;
  padding: 6px 20px !important;
}
.xmx-input-wrapper .xmx-icon-clear,
.xmx-icon-calendar {
  right: 8px !important;
}
$namespace: 'xmx'; // change the 'mx' to 'xmx'. then <date-picker prefix-class="xmx" />

$default-color: #555;
$primary-color: #04b88f;
@import '~vue2-datepicker/scss/index.scss';
</style>
