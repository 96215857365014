import axios from 'axios'
import qs from 'qs'
import router from '@/router/index'

const instance = axios.create({
  baseURL: process.env.VUE_APP_REQUEST_URL,
  timeout: 120000,
  headers: { 'Content-type': 'application/json' }, // application/x-www-form-urlencoded
})

// 添加请求拦截器
instance.interceptors.request.use(config => {
  // Get token from localStorage
  // const accessToken = useJwt.getToken()
  const accessToken = localStorage.getItem('accessToken')

  // If token is present add it to request's Authorization Header
  if (accessToken) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `${accessToken}`
  } else if (window.location.pathname !== '/login') {
    // 如果token失效且当前页面不是登陆页时，跳转登陆页
    router.push({ name: 'auth-login' })
  }
  return config
}, error => {
// 对请求错误做些什么
  Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(response => {
  // 对响应数据做点什么
  if (response.data.code === 401 || response.data.code === 100001 || response.data.code === 100000) {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('userData')
    localStorage.removeItem('currentTime')
    localStorage.removeItem('merchantNo')
    localStorage.removeItem('loginInfo')
    localStorage.removeItem('uuid')
    localStorage.removeItem('currentTimeZone')
    localStorage.removeItem('loglevel:webpack-dev-server')
    localStorage.removeItem('token')
    localStorage.removeItem('navMenuItems')
    localStorage.removeItem('permissions')
    localStorage.removeItem('defaultRouteName')
    router.push({ name: 'auth-login' })
    return
  }
  // eslint-disable-next-line consistent-return
  return response
}, error => {
  // 对响应错误做点什么
  const err = qs.parse(error).response
  let errorInfo = {}
  if (err) {
    errorInfo = err.data
  } else {
    errorInfo = {
      code: 0,
      data: null,
      errorCode: 9999,
      message: 'internal error',
      requestId: '',
    }
  }
  return Promise.reject(errorInfo)
})

export default instance
