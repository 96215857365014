import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import $http from '@/libs/http'

const moment = require('moment-timezone')

/**
 * [trim 清空空格]
 * @param  {[string]}  value
 * @return {string}
 */
export const trim = value => {
  if (!value) return ''
  return value.replace(/(\s*)/g, '')
}

/**
 * [trim 清空空格]
 * @param  {[string]}  setDatetimeDefault
 * @return {string}
 */
export const setDatetimeDefault = (start, end) => {
  if (start && end) {
    return [
      moment().subtract(start, 'days').format('YYYY-MM-DD 00:00:00'),
      moment().subtract(end, 'days').format('YYYY-MM-DD 23:59:59'),
    ]
  }
  return []
}

/**
 * @description 获取到Url里面的参数
 * @param  {[string]}  value
 * @return {string}
 */
export function getQueryString(name) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i')
  const r = window.location.search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}
/**
 * @description 判断值是否定义]
 * @param  {[string]}  value
 * @return {Boolean}
 */
export function isDefine(value) {
  if (value == null || value === '' || value === 'undefined' || value === undefined || value === 'null' || value === '(null)' || value === 'NULL' || typeof (value) === 'undefined') {
    return false
  }
  const newValue = `${value}`.replace(/\s/g, '') // 全局匹配空白字符
  if (newValue === '') {
    return false
  }
  return true
}
/**
 * @description formatTimeReg  2021-08-13T08:14:38.528722748Z
 * @param  {[string]}  value
 * @return {Boolean}
 */
export function formatTimeReg(time) {
  const newDate = new Date(time).toJSON()
  return new Date(+new Date(newDate) + 8 * 3600 * 1000)
    .toISOString()
    .replace(/T/g, ' ')
    .replace(/\.[\d]{3}Z/, '')
}
/**
 * @description formatDate 格式化时间戳
 * @param  {[string]}  value
 * @return {Boolean}
 */
export function formatDate(time) {
  const now = new Date(time * 1000)
  const year = now.getFullYear()
  let month = now.getMonth() + 1
  let date = now.getDate()
  let hour = now.getHours()
  let minute = now.getMinutes()
  let second = now.getSeconds()
  month = month < 10 ? `0${month}` : month
  date = date < 10 ? `0${date}` : date
  hour = hour < 10 ? `0${hour}` : hour
  minute = minute < 10 ? `0${minute}` : minute
  second = second < 10 ? `0${second}` : second
  return (
    `${year
    }-${
      month
    }-${
      date
    } ${
      hour
    }:${
      minute
    }:${
      second}`
  )
}
/**
 * @description updateDate 更新时区时间
 * @param  {[string]}  time
 * @param  {[string]}  timeZone 时区
 * @return {Boolean}
 */
export function updateDate(time, timeZone) {
  if (time === '') return ''
  let currentTimeZone = timeZone
  if (timeZone == 'Asia/Beijing') {
    currentTimeZone = 'Asia/Shanghai'
  }
  return moment
    .tz(time * 1000, currentTimeZone)
    .format('YYYY-MM-DD HH:mm:ss')
}

export function formatPhoneNumber86(phone) {
  if (!phone) return
  // eslint-disable-next-line consistent-return
  return `+${phone}`
}

export function formatPhoneNumber55(phone) {
  if (!phone) return
  const b = /([-+]?\d{1})(?=\d)/g
  // eslint-disable-next-line consistent-return
  return phone.replace(b, ($0, $1, n) => {
    switch (n) {
      case 0:
        return `+${$1}`
      case 1:
        return `${$1}(`
      case 3:
        return `${$1})`
      default:
        return $1
    }
  })
}

/**
 * @description formatPhoneNumber 更新时区时间
 * @param  {[string]}  phone
 * @return {String}
 */
export function formatPhoneNumber(phone) {
  if (!phone) return
  // 清楚数据中可能存在的字母和+ - 符号
  const newPhone = phone.replace(/[A-Za-z+-]/g, '')
  const w = newPhone.substr(0, 2)
  if (w === '86' || w === '52') {
    // eslint-disable-next-line consistent-return
    return formatPhoneNumber86(newPhone)
  }
  if (w === '55') {
    // eslint-disable-next-line consistent-return
    return formatPhoneNumber55(newPhone)
  }
}

/**
 * @description formatCNPJ 更新时区时间
 * @param  {[string]}  cnpj
 * @return {String}
 */
export function formatCNPJ(cnpj) {
  if (!cnpj) return
  const b = /([-+]?\d{1})(?=\d)/g
  // eslint-disable-next-line consistent-return
  return cnpj.replace(b, ($0, $1, n) => {
    switch (n) {
      case 1:
        return `${$1}.`
      case 4:
        return `${$1}.`
      case 7:
        return `${$1}/`
      case 11:
        return `${$1}-`
      default:
        return $1
    }
  })
}

/**
 * @description formatCPF 更新时区时间
 * @param  {[string]}  cpf
 * @return {String}
 */
export function formatCPF(cpf) {
  if (!cpf) return
  const b = /([-+]?\d{3})(?=\d)/g
  // eslint-disable-next-line consistent-return
  return cpf.replace(b, ($0, $1, n) => {
    if (n === 6) {
      return `${$1}-`
    }
    return `${$1}.`
  })
}
/**
 * @description formatDocumentId 更新时区时间
 * @param  {[string]}  id
 * @param  {[string]}  currency
 * @return {String}
 */
export function formatDocumentId(id, currency) {
  if (!id) return
  if (currency === 'MXN') {
    // eslint-disable-next-line consistent-return
    return id
  }
  if (id.length == '11') { // CPF
    // eslint-disable-next-line consistent-return
    return formatCPF(id)
  } if (id.length == '14') { // CNPJ
    // eslint-disable-next-line consistent-return
    return formatCNPJ(id)
  }
}
export function formatDocumentId2(id, type) {
  if (!id) return
  if (type === 'CPF') { // CPF
    // eslint-disable-next-line consistent-return
    return formatCPF(id)
  } if (type === 'CNPJ') { // CNPJ
    // eslint-disable-next-line consistent-return
    return formatCNPJ(id)
  }
  // eslint-disable-next-line consistent-return
  return id
}
/**
 * @description formatAccountNumber 更新时区时间
 * @param  {[string]}  number
 * @return {String}
 */
export function formatAccountNumber(number) {
  if (!number || number.length == 1) return
  // eslint-disable-next-line consistent-return
  return `${number.substr(0, number.length - 1)}-${number[number.length - 1]}`
}
/**
 * @description getDocumentType 通过documentId获取type
 * @param  {[string]}  id
 * @return {String}
 */
export function getDocumentType(id, userDetail) {
  if (!id) return
  if (userDetail.isoCode === 'MEX') {
    // eslint-disable-next-line consistent-return
    return userDetail.documentType
  }
  if (id.length == '11') { // CPF
    // eslint-disable-next-line consistent-return
    return 'CPF'
  } if (id.length == '14') { // CNPJ
    // eslint-disable-next-line consistent-return
    return 'CNPJ'
  }
}
/**
 * @description currentTimezone
 * @param  {[string]}  time
 * @param  {[string]}  timeZone
 * @return {String}
 */
export function currentTimezone(time, timeZone) {
  // timeZone = timeZone || 'America/Sao_Paulo'
  if (timeZone == 'America/Sao_Paulo') {
    return time + 3600 * 3 // 巴西时区 +3小时
  } if (timeZone == 'UTC') {
    return time
  } if (timeZone == 'Asia/Beijing') {
    return time - 3600 * 8
  }
  return 'America/Sao_Paulo'
}
/**
 * @description handlerTimestamp
 * @param  {[string]}  t1
 * @param  {[string]}  t2
 * @param  {[string]}  timeZone
 * @return {String}
 */
export function handlerTimestamp(t1, t2, timeZone) {
  if (t2) {
    t1 = moment.tz(t1, '').unix()
    t2 = moment.tz(t2, '').unix()
    return [currentTimezone(t1, timeZone), currentTimezone(t2, timeZone)]
  }
  t1 = moment.tz(t1, '').unix()
  return currentTimezone(t1, timeZone)
}
/**
 * 提示语
 * @param {Object} 当前页面的this
 * @param {String} variant 相当于title
 * @param {String} message
 */
export function showToast(proxy, variant = null, message = '', title = 'success') {
  proxy.$toast({
    component: ToastificationContent,
    props: {
      title: `${variant || 'Error'}`,
      icon: 'CoffeeIcon',
      variant: title,
      text: message,
    },
  },
  {
    position: 'top-center',
  })
}

export async function updateNavMenuItems() {
  const res = await $http.get('api/v2/admin/auth/profile?force_json=true')
  if (!res) return
  const { code, data } = res.data
  let newFeature = []
  if (code === 200) {
    const { feature_list, role_meta, user_name } = data

    // 临时加
    // feature_list = feature_list.concat([
    //   {
    //     created_at_ms: '0',
    //     description: 'Prize Distribution',
    //     feature_id: 'prize_distribution',
    //     feature_list: [],
    //     feature_name: 'Prize Distribution',
    //     icon: 'AwardIcon',
    //     parent_feature_id: '',
    //     perm: 1,
    //     route: '',
    //   }, {
    //     created_at_ms: '0',
    //     description: 'List',
    //     feature_id: 'prize_distribution_list',
    //     feature_list: [],
    //     feature_name: 'List',
    //     icon: '',
    //     parent_feature_id: 'prize_distribution',
    //     perm: 1,
    //     route: 'Prize Distribution List',
    //   }, {
    //     created_at_ms: '0',
    //     description: 'Generator',
    //     feature_id: 'prize_distribution_generator',
    //     feature_list: [],
    //     feature_name: 'Generator',
    //     icon: '',
    //     parent_feature_id: 'prize_distribution',
    //     perm: 1,
    //     route: 'Prize Distribution Generator',
    //   },
    // ])

    // Download不需要单独授权，只要有可以下载数据页面的权限就有Download权限: deposit withdraw purchases
    const isDownload = feature_list.some(value => (value.feature_id === 'deposit' && value.perm !== 0) || (value.feature_id === 'withdraw' && value.perm !== 0) || (value.feature_id === 'purchases' && value.perm !== 0) || (value.feature_id === 'prize_distribution_list' && value.perm !== 0) || (value.feature_id === 'prize_distribution_generator' && value.perm !== 0))
    const parentNode = feature_list.filter(value => {
      // eslint-disable-next-line no-param-reassign
      value.title = value.feature_name
      if (value.feature_id === 'download') {
        // eslint-disable-next-line no-param-reassign
        value.perm = isDownload ? 1 : 0
      }
      return value.parent_feature_id === ''
    })

    newFeature = feature_list.reduce((prev, next) => {
      if (next.parent_feature_id !== '') {
        const index = prev.findIndex(feature => feature.feature_id === next.parent_feature_id)
        if (!prev[index].children) {
          // eslint-disable-next-line no-param-reassign
          prev[index].children = []
        }
        // eslint-disable-next-line no-unused-expressions
        const isExist = prev[index].children.some(value => value.feature_id === next.feature_id)
        if (!isExist) {
          // eslint-disable-next-line no-param-reassign
          next.title = next.feature_name
          prev[index].children.push(next)
        }
      }
      return prev
    }, parentNode)
    // 如果有子元素时，更新父元素的perm为 1   1：EDIT 2：VIEW
    newFeature.forEach(value => {
      if (value.children) {
        const isPermOne = value.children.some(child => (child.perm === 1 || child.perm === 2))
        // eslint-disable-next-line no-param-reassign
        value.perm = isPermOne ? 1 : 0
      }
    })
  }
  // console.log('后端数据生成的左侧目录=>', newFeature)
  // eslint-disable-next-line consistent-return
  return newFeature
}

export function getDefaultName() {
  // 获取权限下第一个子元素
  if (!localStorage.getItem('navMenuItems')) {
    localStorage.removeItem('navMenuItems')
    return false
  }
  const navMenuItems = JSON.parse(localStorage.getItem('navMenuItems'))
  // console.log('navMenuItems', navMenuItems)
  if (!navMenuItems) {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('userData')
    localStorage.removeItem('currentTime')
    localStorage.removeItem('merchantNo')
    localStorage.removeItem('loginInfo')
    localStorage.removeItem('uuid')
    localStorage.removeItem('currentTimeZone')
    localStorage.removeItem('loglevel:webpack-dev-server')
    localStorage.removeItem('token')
    localStorage.removeItem('navMenuItems')
    localStorage.removeItem('permissions')
    localStorage.removeItem('defaultRouteName')
    return 'login'
  }
  // 可以跳转的全部route
  const premissionMenu = []
  navMenuItems.forEach(value => {
    if (value.children) {
      value.children.forEach(child => {
        if (child.perm !== 0) {
          premissionMenu.push(child.route)
        }
      })
    } else if (value.perm !== 0) {
      premissionMenu.push(value.route)
    }
  })
  // console.log('premissionMenu=> ', premissionMenu)
  // User Center http://localhost:8081/usercenter-list
  return premissionMenu
}
