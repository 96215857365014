const withdraw = [
  {
    path: '/download',
    name: 'Download',
    component: () => import('@/views/download.vue'),
    meta: {
      action: 'read',
    },
  },
  // {
  //   path: '/channel-settings',
  //   name: 'Channel Settings',
  //   component: () => import('@/views/channel-settings.vue'),
  //   meta: {
  //     action: 'read',
  //   },
  // },
]

export default withdraw
