// import { $themeBreakpoints } from '@themeConfig'
import $http from '@/libs/http'
import { updateNavMenuItems, getDefaultName } from '@/libs/utils'

export default {
  namespaced: true,
  state: {
    timeZone: 'America/Sao_Paulo',
    navMenuItems: (localStorage.getItem('navMenuItems') && localStorage.getItem('navMenuItems') !== 'undefined') ? JSON.parse(localStorage.getItem('navMenuItems')) : [],
    countrys: [],
    banks: [],
    currencys: [],
    defaultRouteName: localStorage.getItem('defaultRouteName') || '',
  },
  getters: {
    getCurrencyCountry: state => async () => {
      if (state.currencys.length === 0 || state.countrys.length === 0) {
        await $http.post('/api/v2/meta/code', { code_types: [1, 2, 6] }).then(res => {
          const { data } = res.data
          state.countrys = data[0].code_list
          state.banks = data[1].code_list
          state.currencys = data[2].code_list
        })
      }
    },
  },
  mutations: {
    UPDATE_TIMEZONE(state, val) {
      state.timeZone = val
    },
    UPDATE_NAVMENUITEMS(state, value) {
      if (value) {
        setTimeout(() => {
          state.navMenuItems = []
          state.navMenuItems = value
        // const [defaultRouteName] = getDefaultName()
        // state.defaultRouteName = defaultRouteName
        // localStorage.setItem('defaultRouteName', defaultRouteName)
        }, 50)
      } else {
        setTimeout(async () => {
          state.navMenuItems = await updateNavMenuItems()
          localStorage.setItem('navMenuItems', JSON.stringify(state.navMenuItems))
        // const [defaultRouteName] = getDefaultName()
        // state.defaultRouteName = defaultRouteName
        // localStorage.setItem('defaultRouteName', defaultRouteName)
        }, 50)
      }
    },
    UPDATE_DEFAULTROUTENAME(state, val) {
      state.defaultRouteName = val
    },
  },
  actions: {
    updateTimeZone({ commit }, value) {
      commit('UPDATE_TIMEZONE', value.timeZone)
    },
    changeNavMenuItems({ commit }, value) {
      commit('UPDATE_NAVMENUITEMS', value)
    },
  },
}

// {
//     title: 'Access Control',
//     route: 'access-control',
//     icon: 'ShieldIcon',
//     // acl: {
//     action: 'read',
//     resource: 'Auth',
//     // },
// },
// {
//     title: 'Deposit Detail',
//     route: 'Deposit Details',
//     icon: 'MailIcon'
// },
// {
//     title: 'Withdraw Details',
//     route: 'Withdraw Details',
//     icon: 'MailIcon'
// },
// {
//     title: 'User Details',
//     route: 'User Details',
//     icon: 'MailIcon'
// },
// {
//     title: 'Channel Settings',
//     route: 'Channel Settings',
//     icon: 'SettingsIcon'
// }
