import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
// eslint-disable-next-line import/no-cycle
import { getDefaultName, updateNavMenuItems } from '@/libs/utils'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
// import dashboard from './routes/dashboard'
// import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
// import chartsMaps from './routes/charts-maps'
// import formsTable from './routes/forms-tables'
// import others from './routes/others'
import userCenter from './routes/userCenter'
import merchantCenter from './routes/merchantCenter'
import withdraw from './routes/withdraw'
import settings from './routes/settings'
import accountMgmt from './routes/accountMgmt'
import purchases from './routes/purchases'
import prizeDistribution from './routes/prizeDistribution'

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) { return originalPush.call(this, location, onResolve, onReject) }
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) { return originalReplace.call(this, location, onResolve, onReject) }
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  // eslint-disable-next-line no-underscore-dangle
  base: window.__POWERED_BY_QIANKUN__ ? '/yourActiveRule/' : '/',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: store.state.wallet.defaultRouteName || localStorage.getItem('defaultRouteName') || 'auth-login' } },
    // ...dashboard,
    ...pages,
    // ...chartsMaps,
    // ...formsTable,
    // ...uiElements,
    // ...others,
    ...userCenter,
    ...merchantCenter,
    ...purchases,
    ...withdraw,
    ...settings,
    ...accountMgmt,
    ...prizeDistribution,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (window.location.href.includes('8080')) {
    return next()
  }

  // const userData = JSON.parse(localStorage.getItem('userData')) || {}
  // const pageName = userData.pageName || []
  // if (pageName.length > 0 && pageName.some(value => value === to.name)) {
  //   to.meta.resource = 'Auth'
  // } else if (pageName.length > 0) {
  //   router.push({ name: pageName[0] })
  // }
  // 如果没有获取到 ability,说明是之前有登录的状态没有清除，清空缓存，跳转登录页
  // if (!userData.ability) {
  //   localStorage.removeItem('accessToken')
  //   localStorage.removeItem('refreshToken')
  //   localStorage.removeItem('userData')
  // }
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })
    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    // const userData = getUserData()
    // next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    // next("/")
  }

  const isDetail = ['User Details', 'Deposit Details', 'Withdraw Details', 'Transfer Details', 'Purchases Details'].includes(to.name)
  if (isDetail) {
    return next()
  }

  if (to.name !== 'auth-login' && !isDetail) {
    // 这样写退出才能生效
    // let navMenuItems
    // if (localStorage.getItem('navMenuItems')) {
    //   navMenuItems = JSON.parse(localStorage.getItem('navMenuItems'))
    // } else {
    //   navMenuItems = await updateNavMenuItems()
    //   localStorage.setItem('navMenuItems', JSON.stringify(navMenuItems))
    //   store.dispatch('wallet/changeNavMenuItems', navMenuItems)
    // }

    // 实时更新权限
    // const navMenuItems = await updateNavMenuItems()
    // localStorage.setItem('navMenuItems', JSON.stringify(navMenuItems))
    // store.dispatch('wallet/changeNavMenuItems', navMenuItems)

    // 左侧一级目录
    const defaultRouteNames = getDefaultName()
    localStorage.setItem('defaultRouteName', defaultRouteNames[0])
    if (!defaultRouteNames.includes(to.name)) {
      // 如果 包含在左侧一级目录 不走这里；如果不包含左侧一级目录且是详情，不走这里；只有不包含左侧一级目录且不是详情才走这里
      router.push({ name: defaultRouteNames[0] })
    }
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
