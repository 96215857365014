import $http from '@/libs/http'

export default {
  login2(params) {
    return $http.post('/api/v1/admin/login', params)
  },
  login(params) {
    return $http.post('/api/v2/admin/auth/login', params)
  },
  getQrcode({ platform, user_name, password }) {
    return $http.post('/api/v1/admin/google_qrcode', { platform, user_name, password }, {
      responseType: 'blob',
    })
  },
  getQrcodeError({ platform, user_name, password }) {
    return $http.post('/api/v1/admin/google_qrcode', { platform, user_name, password })
  },

  /**
   * merchant center
   */
  // 商户列表
  fetchMerchantList(params) {
    return $http.post('/api/v2/admin/merchant/list', params)
  },
  // 商户详情
  fetchMerchantDetail(params) {
    return $http.post('/api/v2/admin/merchant/detail', params)
  },
  // 商户状态修改
  changeMerchantStatus(params) {
    return $http.post('/api/v2/admin/merchant/status', params)
  },
  // 商户KYC状态
  changeMerchantKycStatus(params) {
    return $http.post('/api/v2/admin/merchant/kyc_status', params)
  },
  // 商户管理员GA状态解绑
  merchantUserGaUnbound(params) {
    return $http.post('/api/v2/admin/merchant/user/ga_unbound', params)
  },
  // 商户管理员重置密码
  merchantUserResetPassword(params) {
    return $http.post('/api/v2/admin/merchant/user/reset_password', params)
  },
  // 商户订单列表
  fetchMerchantTransactionList(params) {
    return $http.post('/api/v2/admin/merchant/transaction_list', params)
  },
  // 商户订单详情
  fetchMerchantTransactionDetail(params) {
    return $http.post('/api/v2/admin/merchant/transaction_detail', params)
  },

  /**
   * user management
   */
  // 账户搜索
  getAccountList({
    user_name, status, role_id, page_size, page_no,
  }) {
    return $http.post('/api/v2/admin/account/list', {
      user_name, status, role_id, page_size, page_no,
    })
  },
  // 账户保存
  saveAccount(params) {
    return $http.post('/api/v2/admin/account/save', params)
  },
  // 重置密码
  resetPassword({ platform, user_id }) {
    return $http.post('/api/v2/admin/account/reset_password', { platform, user_id })
  },

  /**
   * role management
   */
  // 角色搜索
  fetchRoleList({
    role_name, role_id, page_size, page_no,
  }) {
    return $http.post('/api/v2/admin/role/list', {
      role_name, role_id, page_size, page_no,
    })
  },
  // 角色保存
  saveRole(params) {
    return $http.post('/api/v2/admin/role/save', params)
  },
  // 角色删除
  removeRole({ role_id }) {
    return $http.post('/api/v2/admin/role/remove', { role_id })
  },
  // feature列表
  fetchFeatureList() {
    return $http.post('/api/v2/admin/feature/list', {})
  },
  // 角色详情
  fetchRoleDetail({ role_id }) {
    return $http.post('/api/v2/admin/role/fetch', { role_id })
  },

  /**
   * activity record
   */
  // 活动记录
  fetchActivityList(params) {
    return $http.post('/api/v2/admin/activity/list', params).catch(() => {})
  },

  /**
   * Prize Distribution
   */
  // 兑换列表
  fetchBonusList(params) { return $http.post('/api/v2/admin/bonus/list', params).catch(() => {}) },
  // pay-out
  bonusPayout(params) { return $http.post('/api/v2/admin/bonus/payout', params).catch(() => {}) },
  // group select list
  getGroupSelectList() {
    return $http.post('/api/v2/admin/bonus/group_select_list', {}, {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    }).catch(() => {})
  },
  // 兑换码分组列表
  fetchBonusGroupList(params) { return $http.post('/api/v2/admin/bonus/group_list', params).catch(() => {}) },
  // 兑换码分组保存
  fetchBonusGroupSave(params) { return $http.post('/api/v2/admin/bonus/group_save', params).catch(() => {}) },
  // 兑换码分组删除
  fetchBonusGroupRemove(params) { return $http.post('/api/v2/admin/bonus/group_remove', params).catch(() => {}) },

  // 模板上传
  templateUpload(params) {
    return $http.post('/api/v1/commons/upload', params, { headers: { 'Content-Type': 'multipart/form-data' } }).catch(() => {})
  },
  // 兑换码分组删除
  bonusCodeSend(params) { return $http.post('/api/v2/admin/bonus/code_send', params) },

}
