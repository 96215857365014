const purchases = [
  // purchases
  {
    path: '/purchases',
    name: 'Purchases',
    component: () => import('@/views/purchases/index.vue'),
    meta: {
      action: 'read',
    },
  },
  {
    path: '/purchases/purchases-detail',
    name: 'Purchases Details',
    component: () => import('@/views/purchases/purchasesDetail.vue'),
    meta: {
      layout: 'full',
    },
  },
]

export default purchases
