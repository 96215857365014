import $http from '@/libs/http'

export default {
  namespaced: true,
  state: {
    modeOptions: null,
    withdrawChannelOption: [],
    withdrawStatusOption: null,
  },
  getters: {},
  mutations: {
    updateWithdrawChannelOption(state, value) {
      state.withdrawChannelOption = value
    },
  },
  actions: {
    // fetchMode() {
    //   return new Promise((resolve, reject) => {
    //     $http.get('/api/v1/admin/getModel')
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    fetchChannel() {
      return new Promise((resolve, reject) => {
        $http.get('/api/v1/admin/getChannel')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWalletStatus() {
      return new Promise((resolve, reject) => {
        $http.get('/api/v1/admin/getWalletStatus')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // fetchChannelStatus() {
    //   return new Promise((resolve, reject) => {
    //     $http.get("/api/v1/admin/getChannelStatus")
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    fetchWithdrawList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        $http
          .post('/api/v1/withdraw/withdrawList', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    download(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        $http
          .post('/api/v1/withdraw/download', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
