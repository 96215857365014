const userCenter = [
  // usercenter
  {
    path: '/usercenter-list',
    name: 'User Center',
    component: () => import('@/views/userCenter/userList/UserCenter.vue'),
    meta: {
      action: 'read',
    },
  },
  {
    path: '/usercenter/user-detail',
    name: 'User Details',
    component: () => import('@/views/userCenter/userList/userDetail.vue'),
    meta: {
      action: 'read',
      layout: 'full',
    },
  },
  // deposit
  {
    path: '/deposit-list',
    name: 'Deposit',
    component: () => import('@/views/userCenter/deposit/Deposit.vue'),
    meta: {
      action: 'read',
      // resource: 'Auth',
    },
  },
  {
    path: '/deposit/deposit-detail',
    name: 'Deposit Details',
    component: () => import('@/views/userCenter/deposit/depositDetail.vue'),
    meta: {
      layout: 'full',
    },
  },
  // withdraw
  {
    path: '/withdraw-list',
    name: 'Withdraw',
    component: () => import('@/views/userCenter/withdraw/Withdraw.vue'),
    meta: {
      action: 'read',
    },
  },
  {
    path: '/withdraw/withdraw-detail',
    name: 'Withdraw Details',
    component: () => import('@/views/userCenter/withdraw/withdrawDetail.vue'),
    meta: {
      layout: 'full',
    },
  },
  // transfer
  {
    path: '/transfer',
    name: 'Transfer',
    component: () => import('@/views/userCenter/transfer/Transfer.vue'),
    meta: {
      action: 'read',
    },
  },
  {
    path: '/transfer/transfer-detail',
    name: 'Transfer Details',
    component: () => import('@/views/userCenter/transfer/transferDetail.vue'),
    meta: {
      layout: 'full',
    },
  },
  // KYC Check
  {
    path: '/kyc-check',
    name: 'KYC Check',
    component: () => import('@/views/userCenter/kycCheck/index.vue'),
    meta: {
      action: 'read',
    },
  },
]

export default userCenter
