const prizeDistribution = [
  {
    path: '/prize-distribution-list',
    name: 'Prize Distribution List',
    component: () => import('@/views/prizeDistribution/list/Index.vue'),
    meta: {
      action: 'read',
    },
  },
  {
    path: '/prize-distribution-generator',
    name: 'Prize Distribution Generator',
    component: () => import('@/views/prizeDistribution/generator/Index.vue'),
    meta: {
      action: 'read',
    },
  },
]

export default prizeDistribution
