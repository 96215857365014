<template>
  <div>
    <span
      v-if="item.tType === 'merchant_merchant_id'"
      class="text-nowrap"
    >
      <b-link
        :to="{
          path: '/merchant-detail',
          query: { id: gdata.merchant_id, timezone: timeZone },
        }"
        class="font-weight-bold"
        target="_blank"
      >
        {{ gvalue }}
      </b-link>
    </span>
    <span v-else-if="item.tType === 'merchant_evidence_of_deposit'">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="flat-primary"
        size="sm"
        @click="() =>{ item.callback('evidence_of_deposit', gdata) }"
      >
        View
      </b-button>
    </span>
    <span
      v-else-if="item.tType === 'merchant_deposit_transaction_id'"
      class="text-nowrap"
    >
      <b-link
        :to="{
          path: '/merchant-deposit-detail',
          query: { id: gdata.transaction_id, timezone: timeZone },
        }"
        class="font-weight-bold"
        target="_blank"
      >
        {{ gvalue }}
      </b-link>
    </span>
    <span
      v-else-if="item.tType === 'merchant_withdraw_transaction_id'"
      class="text-nowrap"
    >
      <b-link
        :to="{
          path: '/merchant-withdraw-detail',
          query: { id: gdata.transaction_id, timezone: timeZone },
        }"
        class="font-weight-bold"
        target="_blank"
      >
        {{ gvalue }}
      </b-link>
    </span>
    <span
      v-else-if="item.tType === 'merchant_security'"
      class="text-nowrap"
    >
      <b-button
        variant="secondary"
        class="btn-icon mr-1"
        size="sm"
        @click="() =>{ item.callback('lock',gdata) }"
      ><feather-icon
        icon="UnlockIcon"
      /></b-button>
      <b-button
        variant="success"
        class="btn-icon mr-1"
        size="sm"
        @click="() =>{ item.callback('googleAuthenticator', gdata) }"
      ><feather-icon
        icon="RefreshCcwIcon"
      /></b-button>
    </span>
  </div>

</template>
<script>
import { defineComponent, computed, getCurrentInstance } from '@vue/composition-api'
import { BLink, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default defineComponent({
  name: 'merchant',
  props: ['item', 'gvalue', 'gdata'],
  setup() {
    const { proxy } = getCurrentInstance()
    const { $store } = proxy
    const timeZone = computed(() => $store.state.wallet.timeZone, { immediate: true, deep: true })
    return { timeZone }
  },
  components: {
    BLink, BButton,
  },

  directives: {
    Ripple,
  },
})
</script>
