import './public-path'
import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import previewPictrue from 'preview-pictrue'
import loadingRegister from '@/libs/loading/loading'
import 'preview-pictrue/styles/index.css'
// eslint-disable-next-line import/order
import Viewer from 'v-viewer'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'viewerjs/dist/viewer.css'

import i18n from '@/libs/i18n'
import $api from '@/api/api'
import $http from '@/libs/http'
import Package from '@/package'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
// import '@/@fake-db/db'

Vue.use(loadingRegister)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(Viewer)
Vue.use(previewPictrue)

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(Package)

Vue.directive('hasBtn', {
  // 当被绑定的元素插入到 DOM 中时……
  inserted(el, binding) {
    const { value } = binding
    // console.log(value)
    const navMenuItems = JSON.parse(localStorage.getItem('navMenuItems'))
    // console.log('store.state.wallet.navMenuItems=>', navMenuItems)
    const permissions = [] // 只有edit权限
    // 取所有子页面的 feature_id
    navMenuItems.forEach(menu => {
      if (menu.children) {
        menu.children.forEach(menuChild => {
          // 1：EDIT 2：VIEW edit时说明有按钮权限，才push
          if (menuChild.perm === 1) {
            permissions.push(`${menuChild.feature_id}`)
          }

          if (menuChild.feature_id === 'user_list' && (menuChild.perm === 1 || menuChild.perm === 2)) {
            permissions.push('link_user_list')
          }

          if (menuChild.feature_id === 'user_list' && menuChild.perm === 0) {
            permissions.push('no_user_list')
          }
        })
      } else if (menu.perm === 1) {
        permissions.push(`${menu.feature_id}`)
      }
    })
    // console.log('显示全量权限数据 =》', permissions)
    // ['user_list', 'deposit', 'withdraw', 'kyc_check', 'merchant_deposit', 'merchant_withdraw', 'purchases', 'download', 'deposit_channel', 'withdraw_channel', 'product_list', 'configurations', 'user_management', 'role_management', 'activity_record']
    const hasPermissions = permissions.some(permission => value === permission)
    if (!hasPermissions) {
      el.parentNode.removeChild(el)
    }
  },
})

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.prototype.$http = $http
Vue.prototype.$api = $api

let instance = null
function render(props = {}) {
  const { container } = props
  instance = new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount(container ? container.querySelector('#app') : '#app')
}

// 独立运行时
// eslint-disable-next-line no-underscore-dangle
if (!window.__POWERED_BY_QIANKUN__) {
  render()
}

export async function bootstrap() {
  console.log('[vue] vue wallet-admin-dashboard')
}
export async function mount(props) {
  console.log('[vue] props from main framework-8081', props)
  render(props)
}
export async function unmount() {
  instance.$destroy()
  instance.$el.innerHTML = ''
  instance = null
}
